<template>
  <div class="about">
    <h1>About</h1>
  </div>
</template>

<script>
export default {
  name: 'about',
  components: {
    // HelloWorld
  }
}
</script>
